import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.css'

const containerClass = classNames({
  'markdown-container': true,
})

class MarkdownContainer extends Component {

  render() {
    const { html } = this.props

    return (
      <div
        className={containerClass}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )
  }
}

MarkdownContainer.propTypes = {
  html: PropTypes.string.isRequired,
}

MarkdownContainer.defaultProps = {
  html: undefined,
}

export default MarkdownContainer
