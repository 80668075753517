import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18nLink from '../../../../components/I18nLink'
import Image from '../../../../components/Image'
import Space from '../../../../components/Space'

import documentationCharacterPicture from '../../../../img/artworks/artwork_documentation_character.png'

const verticalInnerNavClass = classNames({
  'documentation-vertical-navigation': true,
})

const titleClass = classNames({
  'documentation-vertical-navigation-title': true,
})

const stickyClass = classNames({
  'documentation-vertical-navigation-sticky': true,
})

class VerticalInnerNavigation extends Component {

  render() {
    const {
      title,
      links,
    } = this.props

    const list = links && links.length > 0 && links.map(link => this.renderLink(link))

    return (
      <div className={verticalInnerNavClass}>
        <Image
          src={documentationCharacterPicture}
          alt="documentation"
          height="92px"
          width="142px"
        />
        <div className={stickyClass}>
          <Space type="xs" />
          <h5 className={titleClass}>
            {title}
          </h5>
          <ul>
            {list}
          </ul>
        </div>
      </div>
    )
  }

  renderLink = (link) => {
    const {
      id,
      title,
    } = link
    const {
      path,
      currentLinkId,
    } = this.props

    const linkClass = classNames({
      'documentation-vertical-navigation-link': true,
      'documentation-vertical-navigation-link-current': id === currentLinkId,
    })

    return (
      <li
        key={`innerLink_${title}`}
        className={linkClass}
      >
        <I18nLink to={`${path}#${id}`}>
          {title}
        </I18nLink>
      </li>
    )
  }
}

VerticalInnerNavigation.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  currentLinkId: PropTypes.string,
}

export default VerticalInnerNavigation
