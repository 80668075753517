/**
 * Returns an array of html elements
 * @param {Object} content A markdown content from graphql
 * @param {string} name An HTML tag name
 */
export const getElementsByTagName = (content, name) => {
  if (!content || !name) {
    return null
  }

  const parser = new DOMParser()
  const document = parser.parseFromString(content, 'text/html')

  if (!document) {
    return null
  }

  return Array.from(document.getElementsByTagName(name))
}


/**
 * Returns the first occurence of an html element
 * @param {Object} content A markdown content from graphql
 * @param {string} name An HTML tag name
 */
export const getFirstElementByTagName = (content, name) => {
  if (!content || !name) {
    return null
  }

  const parser = new DOMParser()
  const document = parser.parseFromString(content, 'text/html')

  if (!document) {
    return null
  }

  return Array.from(document.getElementsByTagName(name))[0]
}
