import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18nLink from '../../../../components/I18nLink'

const horizontalInnerNavClass = classNames({
  'documentation-navigation-horizontal': true,
})

class HorizontalInnerNavigation extends Component {
  render() {
    const { content } = this.props

    return (
      <div className={horizontalInnerNavClass}>
        {content.map(link => this.renderLink(link))}
      </div>
    )
  }

  renderLink = (link) => {
    const {
      name,
      path,
      isCurrent,
    } = link

    const linkClass = classNames({
      'documentation-horizontal-navigation-link': true,
      'documentation-horizontal-navigation-link-current': isCurrent,
    })

    return (
      <span key={`innerLink_${name}`} className={linkClass}>
        <I18nLink to={path}>
          {name}
        </I18nLink>
      </span>
    )
  }
}

HorizontalInnerNavigation.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    isCurrent: PropTypes.bool,
  })),
}

export default HorizontalInnerNavigation
