import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system'
import { injectIntl } from 'react-intl'

import {
  getFirstElementByTagName,
  getElementsByTagName,
} from '../../../utils/html'
import Navigation from '../Navigation'
import MarkdownContainer from '../../components/MarkdownContainer'
import Space from '../../components/Space'
import VerticalInnerNavigation from './components/VerticalInnerNavigation'
import HorizontalInnerNavigation from './components/HorizontalInnerNavigation'

const BREAKPOINT = -50
const PATHS = {
  dataManagement: '/data-management',
  dataProtection: '/data-protection',
  privacyPolicy: '/privacy-policy',
}

class DocumentationDetail extends Component {

  constructor(props) {
    super(props)

    this.state = {
      menuTitle: undefined,
      menuLinks: undefined,
      menuCurrentLinkId: undefined,
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
    this.generateMenuContent()
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }


  // Handlers
  handleScroll = () => {
    const {
      menuCurrentLinkId,
    } = this.state

    // Full browser support
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

    let result = null
    this.getAllAnchors().map(anchor => {
      if (!result) {
        result = anchor
        return null
      }
      const currentPosition = anchor.offsetTop - scrollPosition
      const oldPosition = result.offsetTop - scrollPosition
      if (currentPosition < BREAKPOINT && currentPosition > oldPosition) {
        result = anchor
      }
      return null
    })

    if (result && menuCurrentLinkId !== result.id) {
      this.setState({
        menuCurrentLinkId: result.id,
      })
    }
  }


  // Methods
  generateMenuContent = () => {
    let menuTitle
    const titleElement = getFirstElementByTagName(this.props.content, 'h1')
    if (titleElement) {
      menuTitle = titleElement.innerText
    }

    let menuLinks
    let menuCurrentLinkId
    const subTitleElements = getElementsByTagName(this.props.content, 'h2')
    if (subTitleElements || subTitleElements.length > 0) {
      menuLinks = subTitleElements.map(element => ({
        id: element.lastElementChild && element.lastElementChild.id,
        title: element.innerText,
        offsetTop: element.offsetTop,
      }))

      menuCurrentLinkId = menuLinks[0].id
    }

    this.setState({
      menuTitle,
      menuLinks,
      menuCurrentLinkId,
    })
  }

  getAllAnchors = () => {
    return this.state.menuLinks.map(link => document.getElementById(link.id))
  }


  // Render
  render() {
    const {
      content,
      path,
      intl: {
        formatMessage,
      },
    } = this.props

    const horizontalNavigationContent = [
      {
        name: formatMessage({ id: 'documentation.dataManagement' }),
        path: PATHS.dataManagement,
        isCurrent: path === PATHS.dataManagement,
      },
      {
        name: formatMessage({ id: 'documentation.dataProtection' }),
        path: PATHS.dataProtection,
        isCurrent: path === PATHS.dataProtection,
      },
      {
        name: formatMessage({ id: 'documentation.privacyPolicy' }),
        path: PATHS.privacyPolicy,
        isCurrent: path === PATHS.privacyPolicy,
      },
    ]

    return (
      <Fragment>
        <Navigation>
          <HorizontalInnerNavigation
            content={horizontalNavigationContent}
          />
        </Navigation>

        <ScreenClassRender render={screenClass => {
          const isSmallScreen = ['xs', 'sm', 'md'].includes(screenClass)

          return (
            <Container
              fluid={!isSmallScreen}
              style={{
                paddingLeft: isSmallScreen ? 15 : 0,
                paddingRight: isSmallScreen ? 15 : 0,
              }}
            >
              <Row nogutter={!isSmallScreen}>
                <Hidden xs sm md>
                  <Col lg={3}>
                    {this.renderVerticalNavigation()}
                  </Col>
                </Hidden>
                <Col md={12} lg={6} offset={{ md: 0, lg: 1 }}>
                  <Space type="xxl" />
                  <MarkdownContainer html={content} />
                  <Space type="xxl" />
                </Col>
              </Row>
            </Container>
          )
        }
        } />
      </Fragment>
    )
  }

  renderVerticalNavigation() {
    const {
      menuTitle,
      menuLinks,
      menuCurrentLinkId,
    } = this.state
    const { path } = this.props

    return (
      <VerticalInnerNavigation
        path={path}
        title={menuTitle}
        links={menuLinks}
        currentLinkId={menuCurrentLinkId}
      />
    )
  }
}

DocumentationDetail.propTypes = {
  content: PropTypes.string.isRequired,
  path: PropTypes.oneOf(Object.values(PATHS)),
}

export default injectIntl(DocumentationDetail)
